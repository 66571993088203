
@font-face {
  font-family: "Dana";  
  src: local("dana_fa"),
    url("./assets/fonts/dana_fa.ttf") format("truetype");
}

:root {
  /* --light-red: #A43751; */
  --light-red: #f87691;
  --light-black: #090a0a;
  --light-gray: #abb3c0;
  --answerOptionColor: #FFFFFF;
    --answerOptionActiveColor: #FFFFFF;
    --answerOptionBorderColor: #DFE3EB;
    --selectableCardActiveColor: transparent;
    --answerOptionRadius: 12px;
    --questionBackgroundColor: #FFFFFF;
    --buttonRadius: 32px;
    --buttonPadding: 15px;
    --font: Montserrat;
    --neutral900Color: #090A0A;
    --neutral800Color: #525764;
    --neutral700Color: #6C727A;
    --neutral600Color: #8490A1;
    --neutral500Color: #ABB3C0;
    --neutral400Color: #D1D5DB;
    --neutral300Color: #DFE3EB;
    --neutral200Color: #EAEEF2;
    --neutral100Color: #F7F7F8;
    --blackColor: #000000;
    --whiteColor: #fFFFFF;
    --background1Color: #FFFFFF;
    --background2Color: #F7F8F9;
    --background3Color: #FFFFFF;
    --primary500Color: #f87691;
    --primary400Color: #f87691;
    --primary300Color: #f87691;
    /* --primary500Color: #A43751;
    --primary400Color: #A43751;
    --primary300Color: #A43751; */
    --primary200Color: #FCDCE0;
    --primary100Color: #FFEEEE;
    --secondary1Color: #4ED09A;
    --secondary2Color: #FCBA2F;
    --accentRedColor: #FF8845;
    --accentGreenColor: #34C759;
    --accentYellowColor: #F7C557;
    --accentBlueColor: #68DFFA;
    --primaryButtonShadowColor: rgba(223, 65, 66, 0.31);
    --secondaryButtonShadowColor: rgba(78, 208, 154, 0.31);
    --shadowCardDefault: 0px 12px 24px rgba(108, 114, 122, 0.1), 0px 2px 6px rgba(108, 114, 122, 0.06);
    --countdownTimeBlock: rgba(78, 208, 154, 0.4);
    --additionalDiscountHeaderColor: rgba(223, 65, 66, 0.1);
    --exclusiveOfferLogoBackgroundColor: rgba(223, 65, 66, 0.2);
    --checkoutPriceBannerShadowColor: rgba(223, 65, 66, 0.4);
    --countdownShadowColor: rgba(223, 65, 66, 0.15);
    --choosePlanNotificationBackgroundColor: rgba(223, 65, 66, 0.2);
    --howItWorksActivePointShadowColor: rgba(223, 65, 66, 0.32);
    --priceSectionShadowColor: rgba(223, 65, 66, 0.4);
    --priceSectionShadowSecondaryColor: rgba(223, 227, 235, 0.4);
    --errorInputBackgroundColor: rgba(247, 197, 87, 0.3);
    --checkboxBorderColor: var(--neutral300Color);
    --buttonTextShadowColor: rgba(66, 33, 108, 0.12);
    --pulseButtonShadowColor: rgba(223, 65, 66, 0.4);
}

body {
  height: 100%;
  font-size: 20px;
  margin: 0;
  padding: 0;
  font-family: 'Dana', 'Montserrat';
}

/* p {
  font-size: 22px;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ol, ul {
  list-style: none;
}

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, button, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  padding: 0;
  border: 0;
  margin: 0;
  vertical-align: baseline;
  font-family: 'Dana';
}

* {
  box-sizing: border-box;
}

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, button, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  font: inherit;
}

b, strong {
  font-weight: 900;
}